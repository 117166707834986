var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":"","wrap":""}},[_c('v-col',{attrs:{"md":"5"}},[(_vm.variant==1)?_c('v-select',{staticClass:"q-autocomplete shadow-0",attrs:{"items":_vm.countries,"item-text":"name","item-value":"iso_2","return-object":"","outlined":_vm.outlined,"dense":_vm.dense,"solo":_vm.solo,"menu-props":{ bottom: true, offsetY: true },"background-color":"#fff","hide-details":"auto"},on:{"change":function($event){return _vm.upCountry()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"rounded":"","size":"18","color":"white"}},'v-avatar',attr,false),on),[_c('view-image',{attrs:{"image":("flags/" + (item.iso_2) + ".png"),"ae":"","contain":false}})],1),_c('span',{staticClass:"ml-2 text-bold"},[_vm._v(_vm._s(item.iso_2))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"rounded":"","color":"white"}},[_c('view-image',{attrs:{"image":("flags/" + (item.iso_2) + ".png"),"contain":false}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.iso_2)+" -+"+_vm._s(item.code))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.name))])],1)]}}],null,false,2794318686),model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}):_vm._e(),(_vm.variant==2)?_c('v-select',{staticClass:"q-autocomplete shadow-0 mobile_no_field",attrs:{"items":_vm.countries,"item-text":"code","item-value":"code","return-object":"","outlined":_vm.outlined,"dense":_vm.dense,"solo":_vm.solo,"background-color":"#fff","hide-details":"auto"},on:{"change":function($event){return _vm.upCountry()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
return [_c('span',{staticClass:"ml-2 text-bold"},[_vm._v("+"+_vm._s(item.code))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"rounded":"","color":"white"}},[_c('view-image',{attrs:{"image":("flags/" + (item.iso_2) + ".png"),"contain":false}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.iso_2)+" +"+_vm._s(item.code))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.name))])],1)]}}],null,false,1724265741),model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}):_vm._e()],1),_c('v-col',{attrs:{"md":"7"}},[_c('v-text-field',_vm._g(_vm._b({staticClass:"q-text-field shadow-0 mobile_no_field_number",attrs:{"value":_vm.value,"outlined":_vm.outlined,"solo":_vm.solo,"dense":_vm.dense,"hide-details":"auto","background-color":"#fff","rules":_vm.mobileRules()},on:{"input":function (v) { return _vm.$emit('input', v); },"change":_vm.updateMobileWithCode,"blur":_vm.updateMobileWithCode}},'v-text-field',_vm.$attrs,false),_vm.$listeners))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }